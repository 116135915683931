import * as React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import { getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import Hero from '../components/hero/hero';
import Layout from '../components/layout';

const AboutPage = () => {
  return (
    <StaticQuery
      query={graphql`
        query AboutPage {
          page: contentfulPage(name: { eq: "About" }) {
            name
            excerpt {
              raw
            }
            content {
              raw
            }
            coverImage {
              file {
                url
                fileName
              }
              gatsbyImageData(
                placeholder: DOMINANT_COLOR
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      `}
      render={(data) => {
        let page: IPageProps = data.page;
        const image: IGatsbyImageData | undefined = getImage(page.coverImage);

        return (
          <Layout>
            <Hero background={page.coverImage.file.url} position={'bottom'} />
            <div className="container">
              <div className="row justify-content-center py-5">
                <div className="col-10 d-flex justify-content-center text-center">
                  <h2>
                    <i>
                      <strong>Cloppus:</strong> [from Late Latin] limping, that
                      does not stand up right.
                    </i>
                  </h2>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  {documentToReactComponents(JSON.parse(page.content.raw))}
                </div>
              </div>
            </div>
          </Layout>
        );
      }}
    ></StaticQuery>
  );
};

export default AboutPage;
